<template lang="pug">
.load-container
  FontAwesomeIcon.load(
    :icon="['fas', 'spinner']"
    spin
  )
</template>

<script>

export default {
  name: 'Loading'
}
</script>

<style lang="sass" scoped>
.load-container
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  width: 100%
  height: 100%
  background: #b2b2b21a
  display: flex
  align-items: center
  justify-content: center
  .load
    font-size: 48px
</style>
